<template>
	<div class="Goods">
		<div class="Goods_box">
			<div class="Goods_nav" v-if="!onlyBox">
				<p :class="{'active' : Switch == '2'}" @click="Switch = '2'"><span>箱内物品</span></p>
				<p :class="{'active' : Switch == '1'}" @click="Switch = '1'"><span>最近掉落</span></p>

			</div>
			<div class="rate-list" v-if="Switch == '2'">
				<div class="item" :class="['lv'+(index+1)]" v-for="(item,index) in rateList" :key="index" v-show="item>0">{{ item.toFixed(3) }}%</div>
			</div>
			<div class="Goods_list" v-show="Switch == '1'">
				<div class="Goods_item" :class="['lv'+item.lv]" v-for="(item,index) in historylist" :key="index">
					<div class="img-con">
						<img :src="item.cover" alt="">
					</div>
					<div class="name">{{item.name}}</div>
				</div>
			</div>
			<div class="Goods_list" v-show="Switch == '2'">
				<div class="Goods_item" :class="['lv'+item.lv]" v-for="(item,index) in DetailList" :key="index">
					<div class="probability">
						<div class="price">
							<div class="coins"></div>{{item.bean}}
						</div>
						<div class="rate">概率：{{item.odds_percent}}</div>
					</div>
					<div class="quality_alias">{{ item.quality_alias }}</div>
					<div class="img-con">
						<img :src="item.cover" alt="">
					</div>
					<div class="name">{{item.name}}</div>
					<div class="alias">{{item.dura_alias}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { UnpackingRecord } from '@/network/api.js'
	export default{
		name:'Goods',
		props:{
			DetailList:{//箱内物品列表
				type:Array
			},
			id:{//宝箱id
				type:Number
			},
			onlyBox: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return{
				Switch:'2',//掉落/物品
				historylist:[],//历史掉落列表
				timer: null,
				rateList:[],
			}
		},
		
		created() {
			this.timer = setInterval(()=>{
				this.GetUnpackingRecord()
			},3000)
		},
		beforeDestroy(){
			if(this.timer){
				clearInterval(this.timer)
			}
		},
		methods:{
			//历史掉落
			GetUnpackingRecord(){
				let delay = localStorage.getItem('delay') ? Number(localStorage.getItem('delay')) : 0
				UnpackingRecord({id:this.id, delay}).then((res) => {
					this.historylist = res.data.data.historylist
				})
			}
		},
		watch:{
			id(v){
				// console.log(v)
				this.GetUnpackingRecord()
			},
			DetailList(val){
				if(val){
					let rateList = [0,0,0,0,0]
					val.map(item=>{
						rateList[item.lv-1]+=parseFloat(item.weight)
					})
					this.rateList = rateList
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.Goods{

		box-sizing: border-box;
		.Goods_box{
			.rate-list{
				display: flex;
				gap: 8px;
				height: 24px;
				width: 330px;
				margin: 0 auto 10px;
				.item{
					width: 61px;
					height: 24px;
					@include HScenter;
					font-size: 12px;
					&.lv1{
						border: 1px solid rgba($color: #FFDC5F, $alpha: .2);
						background: linear-gradient(180deg, rgba(255, 221, 134, 0.10) 0%, rgba(255, 221, 134, 0.00) 100%);
						color: #FFDC5F;
					}
					&.lv2{
						border: 1px solid rgba($color: #EA1E4F, $alpha: .2);
						background: linear-gradient(180deg, rgba(234, 30, 79, 0.10) 0%, rgba(234, 30, 79, 0.00) 100%);
						color: #EA1E4F;
					}
					&.lv3{
						border: 1px solid rgba($color: #B91BE0, $alpha: .2);
						background: linear-gradient(180deg, rgba(185, 27, 224, 0.10) 0%, rgba(185, 27, 224, 0.00) 100%);
						color: #B91BE0;
					}
					&.lv4{
						border: 1px solid rgba($color: #5F6FFF, $alpha: .2);
						background: linear-gradient(180deg, rgba(95, 111, 255, 0.10) 0%, rgba(95, 111, 255, 0.00) 100%);
						color: #5F6FFF;
					}
					&.lv5{
						border: 1px solid rgba($color: #ABABAB, $alpha: .2);
						background: linear-gradient(180deg, rgba(171, 171, 171, 0.10) 0%, rgba(171, 171, 171, 0.00) 100%);
						color: #ABABAB;
					}
				}
			}
			.Goods_nav{
				width: 100%;
				display: flex;
				height: 40px;
				margin-top: 25px;
				p{
					color: #999;
					position: relative;
					width: 97px;
					margin-right: 4px;
					text-align: center;
					cursor: pointer;
					&::before{
						content: '';
						position: absolute;
						z-index: 1;
						background-size: 100% 100%;
						width: 100%;
						left: 0;
						top: 0;
						pointer-events: none;
					}
					&:nth-child(1){
						padding-left: 20px;
						&:before{
							background-image: url(~@/assets/img/open-nav-btn-1.png);
							height: 84px;
						}
					}
					&:nth-child(2){
						padding-left: 29px;
						&:before{
							background-image: url(~@/assets/img/open-nav-btn-2.png);
							height: 29px;
						}
					}
					span{
						position: relative;
						z-index: 2;
						line-height: 29px;
						&:before{
							content: '';
							position: absolute;
							left: -6px;
							top: 50%;
							transform: translateY(-50%);
							border-style: solid;
							border-color: transparent transparent transparent #44FDE7;
							border-width: 4px;
							opacity: 0;
						}
						&:after{
							content: '';
							position: absolute;
							right: -6px;
							top: 50%;
							transform: translateY(-50%);
							border-style: solid;
							border-color: transparent #44FDE7 transparent transparent;
							border-width: 4px;
							opacity: 0;
						}
					}
					&.active{
						color: #fff;
						span{
							&::before,&::after{
								opacity: 1;
							}
						}
					}
				}
			}

			.history_list{
				width: 100%;
				display: flex;
				padding: 5px 0;
				flex-wrap: wrap;
				background: rgba($color: #000000, $alpha: .2);
				border-radius: 0 4px 4px 4px;
				margin-top: -1px;
				.history_item{
					width: 25%;
					text-align: center;
					color: #fff;
					margin-bottom: 5px;
					.history_pic{
						width: 80%;
						height:73px;
						margin: 0 auto;
						display: flex;
						justify-content: center;
						align-items: center;
						background-image: url(http://csgo.api.gameskins.cn/uploads/images/baeef9d5696b72118858f87ff955bee7.png);
						background-position: center;
						background-size: 80%;
						background-repeat: no-repeat;
						img{
							max-width: 100%;
							vertical-align: middle;
						}
					}
					p{
						width: 100%;
						padding: 0 3px;
						box-sizing: border-box;
						font-size: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
			.Goods_list{
				width: 330px;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				
				.Goods_item{
					width: 165px;
					height: 130px;
					position: relative;
					@include HScenter;
					flex-direction: column;
					margin-bottom: 9px;
					background: linear-gradient(180deg, #1C1C2C 0%, #0B0B12 100%);
					background-image: url(~@/assets/img/gun-item-bg.png);
					background-size: 100% 100%;
					&.lv1{
						.img-con{
							background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
						}
					}
					&.lv2{
						.img-con{
							background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
						}
					}
					&.lv3{
						.img-con{
							background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
						}
					}
					&.lv4{
						.img-con{
							background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
						}
					}
					&.lv5{
						.img-con{
							background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
						}
					}
					.probability{
						justify-content: space-between;
						display: flex;
						width: 100%;
						height: 25px;
						line-height: 25px;
						text-align: left;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						padding: 0 5px;
						white-space: nowrap;
						.price{
							font-size: 12px;
						}
						.rate{
							color: #ddd;
							font-size: 12px;
						}
					}
					.quality_alias{
						position: absolute;
						right: 16px;
						top: 24px;
						font-size: 12px;
						transform: scale(0.7);
						color: #FFD600;
					}
					.img-con{
						width: 109px;
						height: 72px;
						margin: 14px auto 0;
						display: flex;
						justify-content: center;
						align-items: center;
						// background-image: url(http://csgo.api.gameskins.cn/uploads/images/baeef9d5696b72118858f87ff955bee7.png);
						background-position: center;
						background-size: auto 100%;
						background-repeat: no-repeat;
						img{
							max-width: 80%;
							vertical-align: middle;
						}
					}
					.name{
						max-width: 90%;
						@include wordhide;
					}
					.alias{
						font-size: 12px;
						color: #fff;
					}
				}
			}
		}
	}
</style>
